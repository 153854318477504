import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root',
})
export class HeaderFooterService {
  private url = 'https://gs1pt.org/header_footer/';

  constructor(private http: HttpClient, private sanitizer: DomSanitizer) { }

  getHeaderFooter() {
    return this.http.get(this.url, { responseType: 'text' }).pipe(
      map(response => this.processHtml(response))
    );
  }
  getStyles() {
    return this.http.get(this.url, { responseType: 'text' })
  }
  private processHtml(response: string): { header: SafeHtml, footer: SafeHtml } {
    const splitHtml = response.split('<main id="content" class="site-main post-10591 page type-page status-publish hentry">');

    if (splitHtml.length < 2) {
      throw new Error('Main content not found in the HTML response');
    }

    const headerHtml = splitHtml[0];
    const footerHtml = splitHtml[1].split('</main>')[1];

    const sanitizedHeader = this.sanitizer.bypassSecurityTrustHtml(headerHtml);
    const sanitizedFooter = this.sanitizer.bypassSecurityTrustHtml(footerHtml);

    return { header: sanitizedHeader, footer: sanitizedFooter };
  }
}
