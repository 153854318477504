import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { MaintenanceService } from './maintenance.service';
import { map, retry } from 'rxjs';

export const maintenanceGuard: CanActivateFn = (route, state) => {
  const maintenanceService = inject(MaintenanceService);
  const router = inject(Router);
  const maintenance$ = maintenanceService.getMaintenanceStatus();

  return maintenance$.pipe(
    map((response) => {
      if("value" in response) {
        if(response.value == "true")
          {
            maintenanceService.writeProcessingStatus(false);
            return true
          }
        else {
          maintenanceService.writeProcessingStatus(false);
          router.navigate(['/maintenance']);
          return false;
        }
      } else { 
      console.error(response.error);
      maintenanceService.writeProcessingStatus(false);
      throw response.retry;
      }
    }),
    retry(3)
  )
}
