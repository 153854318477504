@defer(when !loading()){
<div [innerHtml]="headerContent()"></div>
}@placeholder(minimum 500ms) {
<app-header-skeleton></app-header-skeleton>
}
<router-outlet />
@if (maintenanceStatus$ | async) {
  <main style="display: flex; align-items: center; justify-content: center; gap:10px; flex-direction: column;">
    <h1>Estamos a carregar o formulário</h1>
    <app-loading-spinner></app-loading-spinner>
  </main>
}
@defer (when !loading()) {
<div class="footer" [innerHtml]="footerContent()"></div>
}