import { Component, ViewEncapsulation } from '@angular/core';
import { ShellComponent } from './components/gs1shell/shell/shell.component';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module, ReCaptchaV3Service } from 'ng-recaptcha';

export const RECAPTCHA_KEY = '6Lffc3sqAAAAAGXpqQOgEN-McF1PgjIjNlZ2LesQ';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
  encapsulation: ViewEncapsulation.Emulated,
  imports: [ShellComponent, RecaptchaV3Module],
  providers: [
    ReCaptchaV3Service,
    {
      provide: RECAPTCHA_V3_SITE_KEY,
      useValue: RECAPTCHA_KEY,
    },
    ],
  standalone: true
})
export class AppComponent {}
