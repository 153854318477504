import { Routes } from '@angular/router';

export const routes: Routes = [
  {
    path: 'event/:id',
    loadComponent: () => import('./pages/registration/registration.component').then(c => c.RegistrationComponent)
  },
  {
    path: 'training/:id',
    loadComponent: () => import('./pages/registration/registration.component').then(c => c.RegistrationComponent)
  }
];
