import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { delay, Observable, Subject } from 'rxjs';
import { Setting, SettingError } from '../models/Setting.model';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MaintenanceService {

  private HttpClient = inject(HttpClient);
  private routes = environment.routes;
  private isProcessing = new Subject<boolean>();
  constructor() { }

  public getMaintenanceStatus(): Observable<Setting | SettingError> {
    this.isProcessing.next(true);
    return this.HttpClient.get<Setting | SettingError>(this.routes.settingMaintenance).pipe(delay(1000));
  }
  public getProcessingStatus(): Observable<boolean> {
    return this.isProcessing.asObservable();
  }
  public writeProcessingStatus(value: boolean) {
    this.isProcessing.next(value);
  }
}
